<template>
    <div class="vid_data_details" v-if="owner">
        <div class="vid_data_details_avatar">
            <div>
                <strong>{{ ownerFirstLetter }}</strong>
            </div>
        </div>
        <div class="vid_data_details_title">
            <span>by</span>
            {{ owner.username }}
        </div>
        <div class="vid_data_details_type">
            {{ category.name }}
        </div>
        <div :class="['vid_data_details_status', animationStatus]">
            {{ formattedAnimationStatus }}
        </div>
    </div>

    <rendered
        v-if="!limitReachedShown"
        :isGuest="isGuest"
        :isAdmin="isAdmin"
        :owner="owner"
        :isOwner="isOwner"
        :animation="animation"
        :aspect-ratio="aspectRatio"
        :reactivity-trigger="reactivityTrigger"
        :animationStatus="animationStatus"
        :areRenderingSpotsAvailable="areRenderingSpotsAvailable"
        :showLimitReachedPopUp="showLimitReachedPopUp"
        @trigger-render="triggerRender"
    />
    <pending
        v-if="!isGuest && animationStatus === 'pending' && isOwner"
        :animation="animation"
        :aspectRatio="aspectRatio"
        :reactivity-trigger="reactivityTrigger"
        :pendingSpot="pendingSpot"
    />
    <rendering
        v-if="!isGuest && animationStatus === 'rendering'"
        :animationStatus="animationStatus"
        :animationIsRendering="animationIsRendering"
        :aspectRatio="aspectRatio"
        :animation="animation"
        :renderingTime="renderingTime"
        :reactivity-trigger="reactivityTrigger"
        :initial-rendering-time-stored="initialRenderingTimeStored"
    />
    <limitReachedPopUp
        :areRenderingSpotsAvailable="areRenderingSpotsAvailable"
        :limitReachedShown="limitReachedShown"
        :owner="owner"
        :isAdmin="isAdmin"
        :showLimitReachedPopUp="showLimitReachedPopUp"
        @closeLimitReachedPopUp="closeLimitReachedPopUp"
    />
    <!--    <rendered-->
    <!--        :isGuest="isGuest"-->
    <!--        :isAdmin="isAdmin"-->
    <!--        :animation="animation"-->
    <!--    />-->
    <action-buttons
        v-if="!isGuest && isOwner"
        :isOwner="isOwner"
        :animation="animation"
        :category="category"
    />
    <use-as-template
        v-if="!isOwner && !isGuest"
        :animation="animation"
        :category="category"
    />
    <set-as-template
        v-if="isAdmin && canBeSeTaSetAsTemplate"
        :animation="animation"
        @toggleTemplate="toggleTemplate"
    />
</template>
<script>
import Rendered from "./buttons/Rendered.vue";
import Pending from "./buttons/Pending.vue";
import Rendering from "./buttons/Rendering.vue";
import ActionButtons from "./buttons/ActionButtons.vue";
import SetAsTemplate from "./buttons/SetAsTemplate.vue";
import UseAsTemplate from "./buttons/UseAsTemplate.vue";
import LimitReachedPopUp from "./LimitReachedPopUp.vue";

export default {
    name: "AnimationView",
    emits: ['triggerRender', 'toggleTemplate'],
    components: {UseAsTemplate, SetAsTemplate, ActionButtons, Rendered, Pending, Rendering, LimitReachedPopUp},
    props: {
        owner: {
            type: Object,
            default: () => ({})
        },
        pendingSpot: {
            type: Number,
            default: 0
        },
        animation: {
            type: Object,
            default: () => ({})
        },
        areRenderingSpotsAvailable: {
            type: Boolean,
            default: false
        },
        category: {
            type: Object,
            default: () => ({})
        },
        isOwner: {
            type: Boolean,
            default: false
        },
        aspectRatio: {
            type: String,
            default: 'square'
        },
        animationStatus: {
            type: String,
            default: 'not-rendered'
        },
        animationIsRendering: {
            type: Boolean,
            default: false
        },
        isGuest: {
            type: Boolean,
            default: false
        },
        isAdmin: {
            type: [Number, Boolean],
        },
        renderingTime: {
            type: Number,
            default: 0
        },
        initialRenderingTimeStored: {
            type: Number,
            default: 0
        },
        reactivityTrigger: {
            type: Number,
            default: 0
        }
    },
    data: () => {
        return {
            showLimitReachedPopUp: false,
            limitReachedShown: false,
        }
    },
    computed: {
        ownerFirstLetter() {
            return this.owner.username.charAt(0).toUpperCase();
        },
        formattedAnimationStatus() {
            return this.animationStatus.split('-').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join('-');
        },
        canBeSeTaSetAsTemplate() {
            return this.animation.can_be_set_as_template;
        }
    },
    mounted() {
        // console.log(this.animation);
        // console.log(this.owner);
        // console.log(this.isAdmin);
        // console.log('isGuest Preview Actions', this.isGuest);
        // console.log('CANSETASTEMPLATE:::'+this.animation.can_be_set_as_template);
    },
    watch: {
        pendingSpot(newVal) {
            // console.log('Pending Spot in PreviewActions:', newVal);
        }
    },
    methods: {
        toggleTemplate() {
            this.$emit('toggleTemplate');
        },
        triggerRender(renderType) {
            if (!this.areRenderingSpotsAvailable && !this.owner.has_credits && !this.isAdmin) {
                // If the condition is met (no spots, no credits, not admin), show the popup
                this.showLimitReachedPopUp = true;
            } else {
                // Otherwise (there are spots or the owner has credits or the user is an admin), trigger the render
                this.$emit('triggerRender', renderType);
            }

            // Log statements for debugging purposes
            // console.log('RENDERING SPOTS AVAILABLE:', this.areRenderingSpotsAvailable);
            // console.log('CONDITION CHECK:', ((!this.areRenderingSpotsAvailable && !this.owner.has_credits) || !this.isAdmin));
            // console.log('Owner has credits:', this.owner.has_credits);
        },
        closeLimitReachedPopUp() {
            this.showLimitReachedPopUp = false;
            this.limitReachedShown = true;
        }
    }
}
</script>
<style scoped>

</style>
